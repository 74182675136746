<template>
  <div class="payment-details">
    <v-card class="mb-2">
      <v-card-title> Laporan Pembayaran </v-card-title>

      <v-card-text v-text="supplier.data.supp_name"></v-card-text>

      <v-divider></v-divider>

      <v-card-text>
        <date-range-picker v-model="filter.dateRange"></date-range-picker>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#15263b">
      <v-card-title class="pb-1"> Total Pembayaran </v-card-title>

      <v-card-text>
        <h2 v-text="payments.total"></h2>
      </v-card-text>
    </v-card>

    <v-card>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        filled
        hide-details
        @input="getData"
      ></v-text-field>

      <v-data-table
        :headers="payments.headers"
        :items="payments.data"
        :loading="payments.loading"
        :options.sync="options"
        :server-items-length="payments.count"
        class="elevation-1"
      ></v-data-table>
    </v-card>
  </div>
</template>

<script>
import DateRangePicker from '@/components/Pickers/DateRangePicker.vue'

export default {
  components: { DateRangePicker },

  name: 'PaymentDetails',

  data() {
    return {
      filter: {
        dateRange: null,
      },

      payments: {
        count: 0,

        data: [],

        headers: [
          { text: 'No Transaksi', value: 'trans_no' },
          { text: 'Referensi', value: 'reference' },
          { text: 'Supplier', value: 'supp_name' },
          { text: 'Tanggal', value: 'tran_date' },
          { text: 'Jatuh Tempo', value: 'due_date' },
          { text: 'Nilai', value: 'amount' },
        ],

        loading: false,

        total: 0,
      },

      options: {},

      search: '',

      supplier: {
        data: {},

        loading: false,
      },

      supplierID: null,
    }
  },

  watch: {
    options: {
      deep: true,

      handler() {
        this.getData()
      },
    },

    'filter.dateRange'() {
      this.getData()
    },
  },

  mounted() {
    this.supplierID = this.$route.params.supplierID

    this.getSupplier()

    this.getData()
  },

  methods: {
    getData() {
      if (!this.filter.dateRange) return

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.payments.data = []
      this.payments.loading = true

      this.$http
        .get('/purchasing/transactions/report/22/' + this.supplierID, {
          params: {
            search: this.search,
            sortBy: sortBy[0] ? sortBy[0] : '',
            sortDesc: sortDesc[0] ? sortDesc[0] : '',
            page: page,
            itemsPerPage: itemsPerPage,
            startDate: this.dateRange ? this.date2sql(this.dateRange[0]) : '',
            endDate: this.dateRange ? this.date2sql(this.dateRange[1]) : '',
          },
        })
        .then(({ data }) => {
          this.payments.count = data.count

          data.result.forEach((debt) => {
            debt.tran_date = this.sql2date(debt.tran_date)
            debt.due_date = this.sql2date(debt.due_date)
          })

          this.payments.data = data.result
          this.payments.total = data.total
          this.payments.loading = false
        })
    },

    getSupplier() {
      this.supplier.loading = false

      this.$http
        .get('/purchasing/suppliers?supplier_id=' + this.supplierID)
        .then((result) => {
          this.supplier.data = result.data
          this.supplier.loading = false
        })
    },
  },
}
</script>
